import React from 'react';
import Chapter from '../../components/chapter';
import CatalogLayout from '../../layouts/catalog';

export default () => (
  <CatalogLayout title="解决方案">
    <Chapter title="通用 Web 开发" to="/service/web-development/" />
    <Chapter title="系统集成" to="/service/system-integration/" />
    <Chapter title="物联网丨智能硬件" to="/service/internet-of-things/" />
    <Chapter title="跨平台 App 开发" to="/service/application-development/" />
  </CatalogLayout>
);
